<template>
<!------------------FORM FOR SIGN UP------------------>
<Header ></Header>

<div class="bg-blue-200 min-h-screen flex items-center">
   <div class="w-full">
     <h2 class="text-center text-blue-400 font-bold text-2xl uppercase mb-10">Remplisser le formulaire</h2>
     <div class="bg-white p-10 rounded-lg shadow md:w-3/4 mx-auto lg:w-1/2">
       <form action="">
         <div class="mb-5">
           <label for="name" class="block mb-2 font-bold text-gray-600">Nom complet</label>
           <input type="text" id="name" name="name" placeholder="Put in your fullname." class="border border-gray-300 shadow p-3 w-full rounded mb-">
         </div>
         <div class="mb-5">
           <label for="name" class="block mb-2 font-bold text-gray-600">Prenom</label>
           <input type="text" id="name" name="name" placeholder="Put in your fullname." class="border border-gray-300 shadow p-3 w-full rounded mb-">
         </div>
         <div class="mb-5">
           <label for="twitter" class="block mb-2 font-bold text-gray-600">Numéro de téléphone</label>
           <input type="text" id="twitter" name="twitter" placeholder="Put in your fullname." class="border border-red-300 shadow p-3 w-full rounded mb-">
           <p class="text-sm text-red-400 mt-2">Telephone number est obligatoire required</p>
         </div>

         <div class="mb-5">
           <label for="twitter" class="block mb-2 font-bold text-gray-600">Email</label>
           <input type="text" id="twitter" name="twitter" placeholder="Put in your fullname." class="border border-red-300 shadow p-3 w-full rounded mb-">
           <p class="text-sm text-red-400 mt-2">Email est obligatoire</p>
         </div>
         <div class="mb-5">
           <label for="twitter" class="block mb-2 font-bold text-gray-600">Fonction/Position</label>
           <input type="text" id="twitter" name="twitter" placeholder="Put in your fullname." class="border border-red-300 shadow p-3 w-full rounded mb-">
           <p class="text-sm text-red-400 mt-2">Email est obligatoire</p>
         </div>
          <div class="mb-5">
           <label for="twitter" class="block mb-2 font-bold text-gray-600">Message</label>
           <input type="text" id="twitter" name="twitter" placeholder="Put in your fullname." class="border border-red-300 shadow p-3 w-full rounded mb-">
           <p class="text-sm text-red-400 mt-2">Email is required</p>
         </div>
         <div class="mb-5">
           <label for="twitter" class="block mb-2 font-bold text-gray-600">Nom de l'entreprise</label>
           <input type="text" id="twitter" name="twitter" placeholder="Put in your fullname." class="border border-red-300 shadow p-3 w-full rounded mb-">
           <p class="text-sm text-red-400 mt-2">Email est obligatoire</p>
         </div>
         <button class="block w-full bg-black text-white font-bold p-4 rounded-lg">Submit</button>
       </form>
     </div>
   </div>
 </div>

<Footer></Footer>

 <!---<div class="bg-green-200 min-h-screen flex items-center">
   <div class="bg-white p-10 md:w-2/3 lg:w-1/2 mx-auto rounded">
     <form action="">

       <div class="flex items-center mb-5">
         <label for="name" class="w-20 inline-block text-right mr-4 text-gray-500 text-gray-500">Name</label>
         <input name="name" id="name" type="text" placeholder="Your name" class="border-b-2 border-gray-400 flex-1 py-2 placeholder-gray-300 outline-none focus:border-green-400">
       </div>

       <div class="flex items-center mb-10">
         <label for="twitter" class="w-20 inline-block text-right mr-4 text-gray-500 text-gray-500">Twitter</label>
         <input type="text" name="twitter" id="twitter" placeholder="Your Twitter pseudonym" class="border-b-2 border-gray-400 flex-1 py-2 placeholder-gray-300 outline-none focus:border-green-400">
       </div>
       <div class="text-right">
         <button class="py-3 px-8 bg-green-500 text-green-100 font-bold rounded">Submit</button>
       </div>
     </form>
   </div>
 </div> 

 <div class="bg-purple-600 min-h-screen flex items-center text-lg">
   <form action="" class="p-10 md:w-2/3 lg:w-1/2 mx-auto rounded">
     <div class="shadow">
       <div class="flex items-center bg-purple-400 rounded-t-lg border-purple-500 border-b">
         <label for="name" class="w-20 text-right mr-8 p-4 text-purple-200">Name</label>
         <input type="text" name="name" id="name" placeholder="Put in your name" class="flex-1 p-4 pl-0 bg-transparent placeholder-purple-300  outline-none text-white overflow-ellipsis overflow-hidden">
       </div>
       <div class="flex items-center bg-purple-400  rounded-b-lg border-purple-500 mb-10">
         <label for="twitter" class="w-20 text-right p-4 mr-8 text-purple-200">Twitter</label>
         <input type="text" name="twitter" id="twitter" placeholder="Put in Twitter pseudonym" class="flex-1 p-4 pl-0 bg-transparent placeholder-purple-300 outline-none text-white overflow-ellipsis overflow-hidden">
       </div>
     </div>
     <button class="bg-pink-400 block w-full rounded py-4 text-white font-bold shadow">Submit</button>

   </form>
 </div>
<div id="myForm">
  <div class="flex justify-center" @submit.prevent="sendEmail">
    <form class="lg:w-400px rounded mx-7 bg-white">
      <div class="flex justify-end pt-3">
        <div class="mr-5 mb-4 w-9 h-9 cursor-pointer" @click="closeForm"><img :src="images.close" alt="close" original></div>
      </div>
      <div class="px-8 pb-6 space-y-3 justify-center">
        <div class="w-80 font-bold text-base leading-7 pb-2">Laissez-nous un message et nous vous recontacterons rapidement</div>
        <div for="name" class="font-medium text-xs leading-4 text-formlabelColor">Nom complet</div>
        <input v-model="name" type="text" id="name" name="name" class=" w-full h-12 border border-solid rounded pl-3">
        <div for="email" class="font-medium text-xs leading-4 text-formlabelColor">{{$t('formL3')}}</div>
        <input v-model="email" id="email" name="email" type="Email" class=" w-full h-12 border border-solid rounded pl-3">
        <div for="phone" class="font-medium text-xs leading-4 text-formlabelColor">{{$t('formL4')}}</div>
        <input v-model="phone" type="text" id="phone" name="phone" class=" w-full h-12 border border-solid rounded pl-3">
        <div for="country" class="font-medium text-xs leading-4 text-formlabelColor">Message</div>
        <textarea v-model="country" name="country" id="country" cols="30" rows="10" class=" w-full h-20  border border-solid rounded pl-3"></textarea>
        <div  class="pt-2">
          <button :disabled="!formIsValid" class="w-full h-12 text-base leading-5 font-semibold text-white bg-blueNew1 rounded cursor-pointer">Envoyer</button>
        </div>
      </div>
    </form>
  </div>
</div> -->
</template>

<script>

/*Import Images*/
/*------------------IMAGES FORM ------------------*/
import emailjs from 'emailjs-com';
import close from '../../assets/images/img_close.webp'
import Header from '../../components/header/index.vue';
import Footer from '../../components/footer/index.vue' 

export default {
 
  name: "index",
  data () {
    return {
      icons: {
      },
      images:{
        close
      },
      country: '',
      name: '',
      email: '',
      phone: '',
      isFormValid: false
    }
  },
  components: {
    Header, Footer
  },
   watch: {
    name() {
      this.validateForm()
    },
    email() {
      this.validateForm()
    },
    phone () {
      this.validateForm()
    },
    country () {
      this.validateForm()
    }
  },
   computed: {
    formIsValid() {
      return this.name && this.email && this.phone && this.country
    }
  },
  methods: {
    validateForm() {
      this.isFormValid = this.name !== '' && this.email !== '' && this.phone !== '' && this.country
    },
 
    
    sendEmail(e) {
            if (this.isFormValid) {
      try {
        emailjs.sendForm('service_d8pfspc', 'template_lq1ru33', e.target,
        'PPiIi_t78k8srRn0Y', {
          country: this.country,
          name: this.name,
          email: this.email,
          phone: this.phone
        })

      } catch(error) {
          console.log({error})
      } }
      // Reset form field
      this.name = ''
      this.email = ''
      this.phone = ''
      this.country= ''
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/sass/variables.scss";

/* The popup form - hidden by default
#myForm {
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    position: fixed;
    display: none;
    background-color: black;
}

form{
  position: fixed;
    top: 10%;
} */
</style>



